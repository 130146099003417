import { LYSTypography, LYSContainer } from '@core/components/Primitives'
import { useReturnPrismicContent } from '@core/prismic/hooks'
import { ITitleSlice } from '@core/prismic/types'
import style from './index.module.less'

interface Props {
  slice: ITitleSlice
}

const levelMap: { [key: string]: 1 | 2 | 3 | 4 } = {
  heading1: 1,
  heading2: 2,
  heading3: 3,
  heading4: 4,
  heading5: 4,
  heading6: 4,
}

export const getTitleLevel = (heading: keyof typeof levelMap) => {
  return levelMap[heading]
}

const TitleSlice: React.FC<Props> = ({ slice }) => {
  const title = slice.primary.title[0]
  const viewport = slice.primary.viewport
  const level = getTitleLevel(title.type)
  const text = title?.text

  const content = (
    <LYSContainer padded={{ x: true }}>
      <LYSTypography.Title className={style.title} level={level}>
        {text}
      </LYSTypography.Title>
    </LYSContainer>
  )

  return useReturnPrismicContent(content, viewport)
}

export default TitleSlice
