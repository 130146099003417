import cn from 'classnames'
import { LYSContainer } from '@core/components/Primitives'
import { ISearchSlice } from '@core/prismic/types'

import style from './index.module.less'
import { useReturnPrismicContent } from '@core/prismic/hooks'

import SearchInputElement from './searchInputElement'

interface Props {
  slice: ISearchSlice
}

const SearchSlice: React.FC<Props> = ({ slice }) => {
  const html = { __html: slice?.primary?.code[0]?.text }

  const viewport = slice.primary.viewport
  const content = (
    <LYSContainer stretch background={slice.primary.background_color}>
      <div className={cn(style.wrapper)}>
        <SearchInputElement className={style.input} />
        <div className={style.code} dangerouslySetInnerHTML={html} />
      </div>
    </LYSContainer>
  )

  return useReturnPrismicContent(content, viewport)
}

export default SearchSlice
