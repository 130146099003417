import { useMemo } from 'react'
import {
  IExternalOrder,
  IExternalOrdersQuery,
} from '@core/api/ExternalOrders/types'
import { IOrder, IOrdersQuery, OrderingTypes } from '@core/api/Orders/types'
import { IGrossPrice } from '@core/api/Products/types'
import useFetchExternalOrders from '@core/utils/useFetchExternalOrder'
import useFetchOrders from '@core/utils/useFetchOrders'

export interface IOrderWidgetHistoryItem {
  id: string
  date: string
  purchaseOrderNumber: string
  priceTotal: IGrossPrice
  items: number
}

const mapExternalOrder = (order: IExternalOrder): IOrderWidgetHistoryItem => ({
  id: order.id,
  date: order.orderDate,
  purchaseOrderNumber: order.purchaseOrderNumber,
  priceTotal: order.price,
  items: order.items.length,
})

const mapOrder = (order: IOrder): IOrderWidgetHistoryItem => ({
  id: order.id,
  date: order.date,
  purchaseOrderNumber: order.purchaseOrderNumber,
  priceTotal: order.price.total,
  items: order.items.length,
})

export const useOrderWidgetHistory = (limit: number) => {
  const ordersQuery: IOrdersQuery = useMemo(
    () => ({
      limit,
      order: {
        createdAt: OrderingTypes.desc,
      },
    }),
    [limit]
  )
  const externalOrdersQuery: IExternalOrdersQuery = useMemo(
    () => ({
      limit,
    }),
    [limit]
  )

  const [ordersRequest] = useFetchOrders(ordersQuery)
  const [externalOrdersRequest] = useFetchExternalOrders(externalOrdersQuery)
  const isLoading = ordersRequest.isLoading || externalOrdersRequest.isLoading

  const history: IOrderWidgetHistoryItem[] = useMemo(() => {
    if (!ordersRequest.payload || !externalOrdersRequest.payload) return []
    return [
      ...ordersRequest.payload.orders.map(mapOrder),
      ...externalOrdersRequest.payload.externalOrders.map(mapExternalOrder),
    ]
      .sort(
        (left, right) =>
          new Date(right.date).getTime() - new Date(left.date).getTime()
      )
      .slice(0, limit)
  }, [limit, externalOrdersRequest.payload, ordersRequest.payload])

  return {
    isLoading,
    history,
  }
}
