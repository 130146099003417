import React, { useMemo, useRef, useEffect, useState } from 'react'
import cn from 'classnames'
import { usePageContext } from '@core/utils/pages/PageContext'
import styles from './styles.module.less'

export function getPrismicImageUrlWithSizes({
  image,
  width,
  height,
  mobile,
}: {
  image: PrismicImageElement
  width?: number
  height?: number
  mobile?: boolean
}) {
  function roundValue(val: number) {
    const r = mobile ? 100 : 50
    return Math.ceil(val / r) * r
  }

  function multiplyValue(val: number) {
    return mobile ? val * 2 : val * 1.5
  }

  function formatValue(val: number) {
    return roundValue(multiplyValue(val))
  }

  const widthToUse =
    typeof width === 'number' && width <= image.dimensions.width
      ? width
      : image.dimensions.width
  const heightToUse =
    typeof height === 'number' && height <= image.dimensions.height
      ? height
      : image.dimensions.height

  return `${image.url}&w=${formatValue(widthToUse)}&h=${formatValue(
    heightToUse
  )}`
}

type IImgProps = Omit<
  React.DetailedHTMLProps<
    React.ImgHTMLAttributes<HTMLImageElement>,
    HTMLImageElement
  >,
  'src' | 'alt'
>

interface IProps extends IImgProps {
  image: PrismicImageElement
  mobileImage?: PrismicImageElement
}

const PrismicImage: React.FC<IProps> = ({
  image,
  mobileImage,
  className,
  ...props
}) => {
  const {
    props: { isMobile },
  } = usePageContext()
  const [source, setSource] = useState<string | null>(null)
  const pictureRef = useRef<HTMLElement>(null)
  const classNames = useMemo(() => cn(styles.image, className), [className])

  const imageSourceToUse = useMemo(() => {
    return isMobile && mobileImage?.url ? mobileImage : image
  }, [image, mobileImage, isMobile])

  useEffect(() => {
    if (pictureRef) {
      const { width } = pictureRef.current?.getBoundingClientRect() || {}

      setSource(
        getPrismicImageUrlWithSizes({
          image: imageSourceToUse,
          width,
          mobile: isMobile,
        })
      )
    }
  }, [image, pictureRef, isMobile, imageSourceToUse])

  const calculatedPlaceholderStyle = useMemo(() => {
    if (source) {
      return {
        backgroundColor: 'transparent',
      }
    } else {
      return {
        paddingTop: `${
          (image.dimensions.height / image.dimensions.width) * 100
        }%`,
      }
    }
  }, [source, image])

  return (
    <picture
      ref={pictureRef}
      className={styles.picture}
      style={calculatedPlaceholderStyle}
    >
      {!!source && (
        <img
          className={classNames}
          src={source}
          alt={image.alt}
          title={image.alt}
          loading={'lazy'}
          width={image.dimensions.width}
          height={image.dimensions.height}
          {...props}
        />
      )}
    </picture>
  )
}

export default PrismicImage
