import * as React from 'react'
import { LYSCollapse, LYSContainer } from '@core/components/Primitives'
import { IQASlice } from '@core/prismic/types'
import usePrismicPlaceholders from '@core/prismic/usePrismicPlaceholders'
import { convertRichTextToHtml } from '@core/prismic/util'
import style from './index.module.less'
import { useReturnPrismicContent } from '@core/prismic/hooks'

interface Props {
  slice: IQASlice
}

const QASlice: React.FC<Props> = ({ slice }) => {
  const { placeholders } = usePrismicPlaceholders()
  const viewport = slice.primary.viewport

  const getInnerHtml = (text: PrismicTextElement[]) => {
    const htmlString = convertRichTextToHtml(text)

    if (!htmlString) return null

    const textWithPlaceholders = htmlString.replace(
      /%\w+%/g,
      (placeholder) => placeholders[placeholder] || placeholder
    )

    const html = { __html: textWithPlaceholders }

    return <span dangerouslySetInnerHTML={html} />
  }

  const content = (
    <LYSContainer padded={{ x: true }}>
      <div className={style.wrapper}>
        <LYSCollapse className={style.collapse}>
          {slice.items.map((item, index) => (
            <LYSCollapse.Panel key={index} header={getInnerHtml(item.question)}>
              {getInnerHtml(item.answer)}
            </LYSCollapse.Panel>
          ))}
        </LYSCollapse>
      </div>
    </LYSContainer>
  )

  return useReturnPrismicContent(content, viewport)
}

export default QASlice
