import React from 'react'
import cn from 'classnames'
import {
  ButtonBack,
  ButtonBackProps,
  ButtonNext,
  ButtonNextProps,
  CarouselProvider,
  Slider,
} from 'pure-react-carousel'
import { LeftOutlined, RightOutlined } from '@ant-design/icons'
import LYSIcon from '../../LYSIcon'
import DotGroup from '../DotGroup/DotGroup'
import 'pure-react-carousel/dist/react-carousel.es.css'
import styles from './index.module.less'

const SimpleButtonBack: React.FC<Omit<ButtonBackProps, 'children'>> = (
  props
) => {
  return (
    <ButtonBack
      {...props}
      className={cn(
        'ant-btn ant-btn-link ant-btn-circle ant-btn-icon-only',
        styles.button,
        styles.prev
      )}
    >
      <LYSIcon
        className={styles.iconOnBackground}
        size="md"
        component={LeftOutlined}
      />
    </ButtonBack>
  )
}

const SimpleButtonNext: React.FC<Omit<ButtonNextProps, 'children'>> = (
  props
) => {
  return (
    <ButtonNext
      {...props}
      className={cn(
        'ant-btn ant-btn-link ant-btn-circle ant-btn-icon-only',
        styles.button,
        styles.next
      )}
    >
      <LYSIcon
        className={styles.iconOnBackground}
        size="md"
        component={RightOutlined}
      />
    </ButtonNext>
  )
}

const TeaserSliderCarouselProvider: React.FC = ({ children }) => {
  return (
    <CarouselProvider
      visibleSlides={1}
      totalSlides={React.Children.count(children)}
      naturalSlideWidth={1}
      naturalSlideHeight={1}
      isIntrinsicHeight
      infinite
      isPlaying
      interval={6000}
    >
      <div className={styles.slideContainer}>
        <SimpleButtonBack />
        <Slider>{children}</Slider>
        <SimpleButtonNext />
        <DotGroup />
      </div>
    </CarouselProvider>
  )
}

export default TeaserSliderCarouselProvider
