/* eslint-disable react-hooks/rules-of-hooks */
import { useEffect } from 'react'
import cn from 'classnames'
import { Slide } from 'pure-react-carousel'
import { LYSCol, LYSRow, LYSContainer } from '@core/components/Primitives'
import TeaserCollectionCarouselProvider from '@core/components/Primitives/LYSCarousel/TeaserCollectionCarouselProvider'
import PrismicImage from '@core/components/Prismic/PrismicImage'
import { teaserClickEvent, teaserViewEvent } from '@core/events/teaser'
import { useTranslation } from '@core/i18n/i18n'
import PrismicLayoutWarning from '@core/prismic/components/PrsimicLayoutWarning'
import {
  ITeaserCollectionSlice,
  ITeaserElement,
  Viewport,
} from '@core/prismic/types'
import { resolvePrismicLink } from '@core/prismic/util'
import { useServices } from '@core/utils/ioc'
import style from './index.module.less'
import { useReturnPrismicContent, useViewport } from '@core/prismic/hooks'

interface Props {
  slice: ITeaserCollectionSlice
}

const TeaserElement: React.FC<{
  element: ITeaserElement
  borderStyle: 'bordered' | 'borderless'
}> = ({ element, borderStyle }) => {
  const { url, target } = resolvePrismicLink(element.teaser_link)

  const { eventBus } = useServices()

  const handleClick = () => {
    eventBus.publish(teaserClickEvent(element))
  }

  useEffect(() => {
    eventBus.publish(teaserViewEvent(element))
  }, [eventBus, element])

  const image = (
    <PrismicImage
      image={element.teaser_image}
      mobileImage={element.teaser_image_mobile}
      className={style.image}
    />
  )

  return (
    <div
      className={cn(
        style.imageWrapper,
        borderStyle === 'bordered' && style.borderedStyle
      )}
    >
      {url ? (
        <a href={url} target={target} onClick={handleClick}>
          {image}
        </a>
      ) : (
        image
      )}
    </div>
  )
}

const TeaserCollectionSlice: React.FC<Props> = ({ slice }) => {
  const { t } = useTranslation()
  const { isTouchDevice } = useViewport()
  const viewport = slice.primary.viewport
  const numberOfColumns = slice.primary.teaser_style
  const imageBorderStyle = slice.primary.image_border_style
  const isThreeTeaserLayout = numberOfColumns === '3'

  const columnStyle =
    numberOfColumns === '1'
      ? {
          xs: 24,
        }
      : numberOfColumns === '2'
      ? {
          xs: 24,
          md: 12,
        }
      : {
          xs: 24,
          md: 12,
          lg: 6,
        }

  // If there is more than one item on mobile, render a carousel instead
  if (isTouchDevice && slice?.items?.length > 1 && !Viewport.DESKTOP) {
    const teaserCollectionSlides = slice.items.map((item, i) => (
      <Slide key={i} index={i}>
        <TeaserElement key={i} element={item} borderStyle={imageBorderStyle} />
      </Slide>
    ))
    return (
      <LYSContainer>
        <TeaserCollectionCarouselProvider>
          {teaserCollectionSlides}
        </TeaserCollectionCarouselProvider>
      </LYSContainer>
    )
  }

  if (isThreeTeaserLayout && slice.items.length !== 3) {
    return (
      <PrismicLayoutWarning
        warning={t(
          'prismic.warning.teaserCollection.threeTeaserLayoutMismatch'
        )}
      />
    )
  }

  const content = (
    <LYSContainer padded={{ x: true }}>
      <LYSRow gutter={'lg'} align={'stretch'}>
        {isThreeTeaserLayout ? (
          <>
            <LYSCol xs={24} md={16} className={style.teaserLeftColumn}>
              <TeaserElement
                element={slice.items[0]}
                borderStyle={imageBorderStyle}
              />
            </LYSCol>

            <LYSCol xs={24} md={8} className={style.teaserRightColumn}>
              <TeaserElement
                element={slice.items[1]}
                borderStyle={imageBorderStyle}
              />

              <TeaserElement
                element={slice.items[2]}
                borderStyle={imageBorderStyle}
              />
            </LYSCol>
          </>
        ) : (
          slice.items.map((element, index) => (
            <LYSCol {...columnStyle} key={index} className={style.imageCol}>
              <TeaserElement element={element} borderStyle={imageBorderStyle} />
            </LYSCol>
          ))
        )}
      </LYSRow>
    </LYSContainer>
  )

  return useReturnPrismicContent(content, viewport)
}

export default TeaserCollectionSlice
