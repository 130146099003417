import { useTranslation } from '@core/i18n/i18n'
import EmarsysProductRecommendationsSlice from '@core/prismic/components/PageLayout/EmarsysRecommendationsSlice'
import React from 'react'
import { IPageLayout } from '../../types'
import PrismicLayoutWarning from '../PrsimicLayoutWarning'
import ButtonSlice from './ButtonSlice'
import DividerSlice from './DividerSlice'
import FilteredProductCollectionSlice from './FilteredProductCollectionSlice'
import HTMLSlice from './HTMLSlice'
import ImageLinkGridSlice from './ImageLinkGridSlice'
import ImageTextSlice from './ImageTextSlice'
import LayoutSection from './LayoutSection'
import OrdersWidgetSlice from './OrderHistorySlice'
import ProductCollectionSlice from './ProductCollectionSlice'
import ProductListSlice from './ProductListSlice'
import ProductSliderSlice from './ProductSliderSlice'
import QASlice from './QASlice'
import RichTextSlice from './RichTextSlice'
import SearchSlice from './SearchSlice'
import SystemRecommendationsSlice from './SystemRecommendationsSlice'
import TeaserCollectionSlice from './TeaserCollectionSlice'
import TeaserSliderSlice from './TeaserSliderSlice'
import TitleSlice from './TitleSlice'
import { useSelector } from 'react-redux'
import { isAuthenticated as isAuthenticatedSelector } from '@core/store/user/selectors'
import MostBoughtSlice from './MostBoughtSlice'

interface Props {
  layout: IPageLayout
}

function section(node: React.ReactElement, key: any, id: string) {
  return (
    <LayoutSection key={key} id={id}>
      {node}
    </LayoutSection>
  )
}

const PageLayout: React.FC<Props> = ({ layout }) => {
  const { t } = useTranslation()
  const isAuthenticated = useSelector(isAuthenticatedSelector)
  if (!layout.body) {
    return null
  }

  const filteredProductCollectionSlicesWithPagination = layout.body.filter(
    (slice) =>
      slice.slice_type === 'filtered_product_collection' &&
      slice.primary.use_pagination === true
  )

  return (
    <div data-testid={'prismic-layout-section'}>
      {layout.body.map((slice, index) => {
        switch (slice.slice_type) {
          case 'teaser_slider':
            return section(
              <TeaserSliderSlice slice={slice} />,
              index,
              slice.primary.identifier
            )
          case 'title':
            return section(
              <TitleSlice slice={slice} key={index} />,
              index,
              slice.primary.identifier
            )
          case 'rich_text':
            return section(
              <RichTextSlice slice={slice} key={index} />,
              index,
              slice.primary.identifier
            )
          case 'teaser_collection':
            return section(
              <TeaserCollectionSlice slice={slice} key={index} />,
              index,
              slice.primary.identifier
            )
          case 'divider':
            return section(
              <DividerSlice slice={slice} key={index} />,
              index,
              slice.primary.identifier
            )
          case 'product_slider':
            return section(
              <ProductSliderSlice slice={slice} key={index} />,
              index,
              slice.primary.identifier
            )
          case 'product_collection':
            return section(
              <ProductCollectionSlice slice={slice} key={index} />,
              index,
              slice.primary.identifier
            )
          case 'html':
            return section(
              <HTMLSlice slice={slice} key={index} />,
              index,
              slice.primary.identifier
            )
          case 'qa':
            return section(
              <QASlice slice={slice} key={index} />,
              index,
              slice.primary.identifier
            )
          case 'button':
            return section(
              <ButtonSlice slice={slice} key={index} />,
              index,
              slice.primary.identifier
            )
          case 'emarsys_recommendations':
            return section(
              <EmarsysProductRecommendationsSlice slice={slice} key={index} />,
              index,
              slice.primary.identifier
            )
          case 'image_and_text':
            return section(
              <ImageTextSlice slice={slice} key={index} />,
              index,
              slice.primary.identifier
            )
          case 'image_link_grid':
            return section(
              <ImageLinkGridSlice slice={slice} key={index} />,
              index,
              slice.primary.identifier
            )
          case 'system_recommendations':
            return section(
              <SystemRecommendationsSlice slice={slice} key={index} />,
              index,
              slice.primary.identifier
            )
          case 'search':
            return section(
              <SearchSlice slice={slice} key={index} />,
              index,
              slice.primary.identifier
            )
          case 'most_bought':
            return section(
              <MostBoughtSlice slice={slice} key={index} />,
              index,
              slice.primary.identifier
            )
          case 'orders':
            return isAuthenticated
              ? section(
                  <OrdersWidgetSlice slice={slice} key={index} />,
                  index,
                  slice.primary.identifier
                )
              : null
          case 'product_list':
            return isAuthenticated
              ? section(
                  <ProductListSlice slice={slice} key={index} />,
                  index,
                  slice.primary.identifier
                )
              : null
          case 'filtered_product_collection':
            return filteredProductCollectionSlicesWithPagination.indexOf(
              slice
            ) === -1 ||
              filteredProductCollectionSlicesWithPagination.indexOf(slice) ===
                0 ? (
              section(
                <FilteredProductCollectionSlice slice={slice} key={index} />,
                index,
                slice.primary.identifier
              )
            ) : (
              <PrismicLayoutWarning
                key={index}
                warning={t('prismic.warning.multiplePagination')}
              />
            )
        }
      })}
    </div>
  )
}

export default PageLayout
