/* eslint-disable react-hooks/rules-of-hooks */
import React from 'react'
import { IProductsQuery } from '@core/api/Products/types'
import { LYSCol, LYSContainer, LYSRow } from '@core/components/Primitives'
import ProductCollection from '@original/components/ProductCollection'
import ProductSkeleton from '@core/components/ProductCollection/ProductCollectionItemSkeleton'
import config from '@core/config/config'
import { IFilteredProductCollection } from '@core/prismic/types'
import { useServices } from '@core/utils/ioc'
import useFetchProducts from '@core/utils/useFetchProducts'
import { useReturnPrismicContent } from '@core/prismic/hooks'
import { PrismicSettingsProvider } from '../PrismicSettingsContext'

interface IProps {
  slice: IFilteredProductCollection
}

function removeNonRelatedFilters(query: IProductsQuery) {
  const queryCopy = Object.assign({}, query)

  delete queryCopy.page
  delete queryCopy.offset
  delete queryCopy.limit

  return queryCopy
}

const FilteredProductCollectionSlice: React.FC<IProps> = ({ slice }) => {
  const { productQueryService } = useServices()
  const {
    filter_query,
    product_count_limit,
    taxonomy_id,
    use_pagination,
    viewport,
    minimized,
  } = slice.primary
  const prismicContextValue = React.useMemo(() => ({ minimized }), [minimized])

  const productsQueryFilter = productQueryService.parseQueryWithTaxonomy(
    taxonomy_id,
    filter_query
  )

  const cleanedProductsQueryFilter =
    removeNonRelatedFilters(productsQueryFilter)

  const query = productQueryService.parseQuery()

  const [{ isLoading, payload, isError }] = useFetchProducts({
    ...cleanedProductsQueryFilter,
    limit: use_pagination
      ? query.limit
      : product_count_limit || config.productCollection.defaultLimit,
    page: use_pagination ? query.page : 1,
    offset: use_pagination ? query.offset : undefined,
  })

  const LoadingState: React.FC = () => {
    return (
      <LYSRow gutter={'md'}>
        {[...Array(config.productCollection.defaultLimit)].map((_, index) => {
          return (
            <LYSCol xs={24} sm={12} lg={8} xl={6} key={index}>
              <ProductSkeleton />
            </LYSCol>
          )
        })}
      </LYSRow>
    )
  }
  if (isLoading) {
    return <LoadingState />
  }

  if (isError || !payload) {
    return null
  }

  const content = (
    <PrismicSettingsProvider value={prismicContextValue}>
      <LYSContainer padded={{ x: true }}>
        <ProductCollection
          initialData={payload}
          hideControls={true}
          hidePagination={!use_pagination}
          showCategoryFilter
        />
      </LYSContainer>
    </PrismicSettingsProvider>
  )

  return useReturnPrismicContent(content, viewport)
}

export default FilteredProductCollectionSlice
