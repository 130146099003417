import React from 'react'
import { Slide } from 'pure-react-carousel'
import { LYSButton, LYSTypography } from '@core/components/Primitives'
import TeaserSliderCarouselProvider from '@core/components/Primitives/LYSCarousel/TeaserSliderCarousel/TeaserSliderCarouselProvider'
import { getPrismicImageUrlWithSizes } from '@core/components/Prismic/PrismicImage'
import { ITeaserSlider, ITeaserSlide } from '@core/prismic/types'
import { convertRichTextToHtml, resolvePrismicLink } from '@core/prismic/util'
import { getTitleLevel } from '../TitleSlice'
import styles from './index.module.less'
import { isEmpty } from 'lodash'
import { useReturnPrismicContent, useViewport } from '@core/prismic/hooks'
import cn from 'classnames'

interface IProps {
  slice: ITeaserSlider
}

const TeaserSlide: React.FC<{
  item: ITeaserSlide
  borderStyle: 'bordered' | 'borderless'
}> = ({ item, borderStyle }) => {
  const { isTouchDevice } = useViewport()
  const {
    image,
    copy,
    headline,
    link,
    c2a,
    text_color,
    mobile_image,
    viewport,
  } = item
  const title = headline[0]
  const level = getTitleLevel(title?.type)
  const text = title?.text
  const htmlString = convertRichTextToHtml(copy)
  const linkTarget = link && resolvePrismicLink(link)
  const teaserImage =
    isTouchDevice && !isEmpty(mobile_image) ? mobile_image : image

  const content = (
    <div
      className={cn(
        styles.imageWrapper,
        borderStyle === 'bordered' && styles.borderedStyle
      )}
    >
      {linkTarget && !c2a ? (
        <a
          href={linkTarget.url}
          target={linkTarget.target}
          onClick={(e) => e.stopPropagation()}
        >
          <div
            className={styles.slide}
            style={{
              backgroundImage: `url(${getPrismicImageUrlWithSizes({
                image: teaserImage!,
                height: 560,
                mobile: isTouchDevice,
              })})`,
              backgroundSize:
                borderStyle === 'borderless' ? 'contain' : 'cover',
              color: text_color,
            }}
          >
            <div className={styles.container}>
              {text && (
                <LYSTypography.Title className={styles.title} level={level}>
                  {text}
                </LYSTypography.Title>
              )}

              {htmlString && htmlString !== '<p></p>' && (
                <span
                  dangerouslySetInnerHTML={{ __html: htmlString }}
                  className={styles.copyHolder}
                />
              )}
            </div>
          </div>
        </a>
      ) : (
        <div
          className={styles.slide}
          style={{
            backgroundImage: `url(${getPrismicImageUrlWithSizes({
              image: teaserImage!,
              height: 560,
              mobile: isTouchDevice,
            })})`,
            backgroundSize: borderStyle === 'borderless' ? 'contain' : 'cover',
            color: text_color,
          }}
        >
          <div className={styles.container}>
            {text && (
              <LYSTypography.Title className={styles.title} level={level}>
                {text}
              </LYSTypography.Title>
            )}

            {htmlString && htmlString !== '<p></p>' && (
              <span
                dangerouslySetInnerHTML={{ __html: htmlString }}
                className={styles.copyHolder}
              />
            )}
            {linkTarget && c2a && (
              <a
                href={linkTarget.url}
                target={linkTarget.target}
                onClick={(e) => e.stopPropagation()}
              >
                <LYSButton
                  className={styles.cta}
                  actionButtonStyle
                  type="primary"
                >
                  {c2a}
                </LYSButton>
              </a>
            )}
          </div>
        </div>
      )}
    </div>
  )

  return useReturnPrismicContent(content, viewport)
}

const TeaserSliderSlice: React.FC<IProps> = ({ slice }) => {
  const { items, primary } = slice
  const viewport = primary.viewport
  const borderStyle = primary.image_border_style

  if (!items.length) {
    return null
  }

  const isSingleSlide = items.length === 1
  /**
   * If we only got one slide there is no need of initializing the whole slider
   */
  if (isSingleSlide) {
    return <TeaserSlide borderStyle={borderStyle} item={items[0]} />
  }

  const teaserSlidesSlices = items.map((item, index) => (
    <Slide
      key={index}
      index={index}
      style={{
        padding: borderStyle === 'borderless' ? 0 : '8px',
      }}
    >
      <TeaserSlide
        borderStyle={borderStyle}
        item={item}
        key={`slide-${index}`}
      />
    </Slide>
  ))

  const content = (
    <div className={styles.slideContainer}>
      <TeaserSliderCarouselProvider>
        {teaserSlidesSlices}
      </TeaserSliderCarouselProvider>
    </div>
  )

  // eslint-disable-next-line react-hooks/rules-of-hooks
  return useReturnPrismicContent(content, viewport)
}

export default TeaserSliderSlice
