import Link from 'next/link'
import { useOrderWidgetHistory } from '@core/components/Me/Dashboard/widgets/OrdersWidget/useOrderWidgetHistory'
import { LYSButton, LYSTypography } from '@core/components/Primitives'
import routes from '@core/config/routes'
import { useTranslation } from '@core/i18n/i18n'

import style from './index.module.less'
import OrderWidgetTable from './OrderWidgetTable'
import { IOrdersSlice } from '@core/prismic/types'
import { useReturnPrismicContent } from '@core/prismic/hooks'

const MAX_NUMBER_OF_ORDERS = 4

interface Props {
  slice: IOrdersSlice
}

const OrdersWidgetSlice: React.FC<Props> = ({ slice }) => {
  const { t } = useTranslation()
  const { isLoading, history } = useOrderWidgetHistory(MAX_NUMBER_OF_ORDERS)
  const viewport = slice.primary.viewport
  const content = (
    <div className={style.container}>
      <LYSTypography.Title level={3}>
        {t('orders.lastOrderText')}
      </LYSTypography.Title>
      <div className={style.content}>
        <OrderWidgetTable history={history} isLoading={isLoading} />
      </div>

      <Link {...routes.orderOverview()}>
        <LYSButton type="link" className={style.button}>
          {t('orders.allOrderText')}
        </LYSButton>
      </Link>
    </div>
  )

  return useReturnPrismicContent(content, viewport)
}

export default OrdersWidgetSlice
