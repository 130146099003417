import React, { useCallback, useEffect, useState } from 'react'
import uniqueId from 'lodash/uniqueId'
import { IProductSearch, ProductPromotionType } from '@core/api/Products/types'
import { LYSTypography } from '@core/components/Primitives'
import ProductCarousel from '@core/components/ProductCollection/ProductCarousel'
import ProductCarouselItem from '@core/components/ProductCollection/ProductCarousel/ProductCarouselItem'
import PromotionContext from '@core/components/shared/PromotionContext'
import { productCollectionViewEvent } from '@core/events/product'
import { useTranslation } from '@core/i18n/i18n'
import { addRecommendCommand } from '@core/modules/emarsys-tracking'
import {
  EmarsysRecommendationLogic,
  EmarsysRecommendationResult,
} from '@core/modules/emarsys-tracking/types'
import { IEmarsysFallbackItem } from '@core/prismic/types'
import {
  consentChangedEvent,
  IConsentChangedEvent,
} from '@core/utils/consent/events'
import { useServices } from '@core/utils/ioc'
import getSku from '@core/utils/models/getSku'
import { usePageContext } from '@core/utils/pages/PageContext'
import styles from './index.module.less'

const DEFAULT_LIMIT = 10

interface Props {
  logic: EmarsysRecommendationLogic
  limit?: number
  fallbackItems?: IEmarsysFallbackItem[]
}

const emarsysProductItemRenderer = (product: IProductSearch) => {
  // Emarsys tracks clicks on recommended products, thus we need to add the
  // data attribute to an element
  // Just wrapping the item in a div is the easiest way and seems to work for now,
  // but might break if the carousel changes styles

  return (
    <div
      data-scarabitem={getSku(product, product.mainVariant)}
      className={styles.itemWrapper}
    >
      <ProductCarouselItem product={product} />
    </div>
  )
}

const EmarsysRecommendations: React.FC<Props> = ({
  logic,
  limit,
  fallbackItems,
}) => {
  const { t } = useTranslation()
  const { api, eventBus, consent } = useServices()
  const pageContext = usePageContext()
  const [containerId, setContainerId] = useState<string>('')
  const [products, setProducts] = useState<IProductSearch[]>([])
  const [isEmarsysProductLoading, setIsEmarsysProductLoading] =
    useState<boolean>(false)
  // Only initialize if we have consent
  const [hasConsent, setHasConsent] = useState<boolean>(
    consent.consent?.marketing || false
  )

  // Only render the component in browser, otherwise we may get different DOM trees when mounting the app in the browser
  // (server-side would always render missing consent paragraph while client would render the slider)
  const [isBrowser, setBrowser] = useState<boolean>(false)

  limit = limit || DEFAULT_LIMIT

  useEffect(() => {
    setBrowser(true)
  }, [])

  // (Re)initialize when user has given consent to marketing cookies
  useEffect(() => {
    return eventBus.subscribe(
      consentChangedEvent.type,
      (e: IConsentChangedEvent) => {
        setHasConsent(e.payload.consent.marketing)
      }
    )
  }, [eventBus, setHasConsent])

  const fetchProducts = useCallback(
    async (productSkus?: string[]) => {
      if (!productSkus || productSkus.length <= 0) {
        setIsEmarsysProductLoading(false)
        return
      }

      setIsEmarsysProductLoading(true)

      const fetchedProducts = await api.products.fetchProducts({
        filter: { sku: productSkus },
        page: 1,
        requiresAggregations: 0,
      })

      setProducts(
        fetchedProducts?.hits.sort(
          (a, b) => productSkus.indexOf(a.sku) - productSkus.indexOf(b.sku)
        )
      )
      setIsEmarsysProductLoading(false)
    },
    [setProducts, api.products, setIsEmarsysProductLoading]
  )

  // Init logic for loading recommendations from Emarsys
  useEffect(() => {
    const fallbackProductSkus = fallbackItems?.map((el) => el.fallback_product)

    if (hasConsent) {
      // Emarsys needs to know the ID of the container that will render the
      // recommendations, since it registers click handlers on it
      // Generating the ID should only happen in browser and after mount to prevent
      // React complaining about differences between server / browser DOM
      // That's why this should be kept in useEffect
      const generatedId = `emarsys-recommendations-${uniqueId()}`
      setContainerId(generatedId)

      const handleRecommendations = async (
        recommendations: EmarsysRecommendationResult
      ) => {
        if (recommendations?.page?.products.length) {
          const productSkus = recommendations.page.products.map((p) => p.item)
          fetchProducts(productSkus)
        } else {
          fetchProducts(fallbackProductSkus)
        }
      }

      addRecommendCommand({
        containerId: generatedId,
        logic,
        limit,
        success: handleRecommendations,
      })
    } else {
      fetchProducts(fallbackProductSkus)
    }
  }, [logic, limit, hasConsent, fallbackItems, fetchProducts])

  useEffect(() => {
    if (products?.length) {
      eventBus.publish(
        productCollectionViewEvent(
          products,
          {
            isPromotion: true,
            promotionType: ProductPromotionType.Recommendation,
          },
          pageContext
        )
      )
    }
  }, [eventBus, products, pageContext])

  return isBrowser ? (
    products.length > 0 ? (
      <PromotionContext.Provider
        value={{
          isPromotion: true,
          promotionType: ProductPromotionType.Recommendation,
        }}
      >
        <div id={containerId}>
          <ProductCarousel
            productItems={products}
            loading={isEmarsysProductLoading}
            customItemRenderer={emarsysProductItemRenderer}
          />
        </div>
      </PromotionContext.Provider>
    ) : (
      <div className={styles.missingConsent}>
        <LYSTypography.Paragraph>
          {t('emarsys.recommendations.missingConsent')}
        </LYSTypography.Paragraph>
      </div>
    )
  ) : (
    <div />
  )
}

export default EmarsysRecommendations
