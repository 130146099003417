import { LYSTypography } from '@core/components/Primitives'

import { useTranslation } from '@core/i18n/i18n'

import style from './index.module.less'
import { IMostBoughtSlice } from '@core/prismic/types'
import MostBoughtTable from './MostBoughtTable'
import useRecommendations from '@core/hooks/useRecommendations'
import { RecommendationTypes } from '@core/api/Recommendations/types'
import { useReturnPrismicContent } from '@core/prismic/hooks'

interface Props {
  slice: IMostBoughtSlice
}

const MostBoughtSlice: React.FC<Props> = ({ slice }) => {
  const { t } = useTranslation()
  const { isLoading, recommendations } = useRecommendations({
    recommendationType: RecommendationTypes.MOST_BOUGHT_VARIANT,
  })
  const viewport = slice.primary.viewport
  const content = (
    <div className={style.container}>
      <LYSTypography.Title level={3}>
        {t('mostBought.tableHead')}
      </LYSTypography.Title>
      <div className={style.content}>
        <MostBoughtTable
          products={recommendations?.slice(0, 3)}
          isLoading={isLoading}
        />
      </div>
    </div>
  )
  return useReturnPrismicContent(content, viewport)
}

export default MostBoughtSlice
