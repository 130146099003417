import { LYSContainer } from '@core/components/Primitives'
import EmarsysRecommendations from '@core/modules/emarsys-tracking/components/EmarsysRecommendations'
import { useReturnPrismicContent } from '@core/prismic/hooks'
import { IEmarsysRecommendationsSlice } from '@core/prismic/types'
import React from 'react'
import { PrismicSettingsProvider } from '../PrismicSettingsContext'

interface Props {
  slice: IEmarsysRecommendationsSlice
}

const EmarsysProductRecommendationsSlice: React.FC<Props> = ({ slice }) => {
  const { logic, limit, viewport, minimized } = slice.primary
  const prismicContextValue = React.useMemo(() => ({ minimized }), [minimized])

  const content = (
    <PrismicSettingsProvider value={prismicContextValue}>
      <LYSContainer padded={{ x: true }}>
        <EmarsysRecommendations
          logic={logic}
          limit={limit}
          fallbackItems={slice.items}
        />
      </LYSContainer>
    </PrismicSettingsProvider>
  )

  return useReturnPrismicContent(content, viewport)
}

export default EmarsysProductRecommendationsSlice
