import { useEffect, useMemo, useState } from 'react'
import flatten from 'lodash/flatten'
import values from 'lodash/values'
import { IProductsQuery } from '@core/api/Products/types'
import useFetchProducts from '@core/utils/useFetchProducts'
import { IProductSliderElement } from './types'

const useGetProductCollectionFromSkuArray = (
  productSkus?: IProductSliderElement[]
) => {
  const [isLoading, setLoading] = useState<boolean>(false)

  // we need to check for whitespace or line breaks in user input
  const productCodes: string[] = flatten(
    productSkus?.map((item) => values(item))
  ).filter((code) => code && code.trim())

  const productsQueryFilter: IProductsQuery = {
    filter: { sku: productCodes },
  }

  const [productFetchData] = useFetchProducts(
    productsQueryFilter,
    !productCodes.length
  )

  useEffect(() => {
    setLoading(productFetchData.isLoading)
  }, [productFetchData])

  const productCollection = productFetchData.payload

  const productItemsSorted = useMemo(
    () =>
      productCollection?.hits !== undefined
        ? productCollection?.hits.sort(
            (a, b) => productCodes.indexOf(a.sku) - productCodes.indexOf(b.sku)
          )
        : [],
    [productCollection, productCodes]
  )
  return { productItemsSorted, isLoading }
}

export default useGetProductCollectionFromSkuArray
