import React from 'react'
import { CarouselProvider, Slider } from 'pure-react-carousel'
import 'pure-react-carousel/dist/react-carousel.es.css'
import DotGroup from './DotGroup/DotGroup'

interface Props {
  children: React.ReactNode
}

const TeaserCollectionCarouselProvider: React.FC<Props> = ({ children }) => {
  return (
    <CarouselProvider
      visibleSlides={1}
      totalSlides={React.Children.count(children)}
      naturalSlideWidth={1}
      naturalSlideHeight={1}
      isIntrinsicHeight
      infinite
    >
      <Slider>{children}</Slider>
      <DotGroup />
    </CarouselProvider>
  )
}

export default TeaserCollectionCarouselProvider
