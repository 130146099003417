/* eslint-disable react-hooks/rules-of-hooks */
import cn from 'classnames'
import { LYSContainer } from '@core/components/Primitives'
import { IRichTextSlice } from '@core/prismic/types'
import usePrismicPlaceholders from '@core/prismic/usePrismicPlaceholders'
import { convertRichTextToHtml } from '@core/prismic/util'
import style from './index.module.less'
import { useReturnPrismicContent } from '@core/prismic/hooks'

interface Props {
  slice: IRichTextSlice
}

const RichTextSlice: React.FC<Props> = ({ slice }) => {
  const viewport = slice.viewport
  const htmlString = convertRichTextToHtml(slice.primary.text)
  const { placeholders } = usePrismicPlaceholders()
  if (!htmlString) return null

  const textWithPlaceholders = htmlString.replace(
    /%\w+%/g,
    (placeholder) => placeholders[placeholder] || placeholder
  )

  const maxWidthStyle =
    slice.primary.width !== 0 && slice.primary.width !== undefined
      ? { maxWidth: `${slice.primary.width}px` }
      : undefined

  const html = { __html: textWithPlaceholders }

  const content = (
    <LYSContainer padded={{ x: true }}>
      <div className={cn(style.wrapper, style[slice.primary.alignment])}>
        <div
          dangerouslySetInnerHTML={html}
          style={maxWidthStyle}
          className={style.text}
        />
      </div>
    </LYSContainer>
  )

  return useReturnPrismicContent(content, viewport)
}

export default RichTextSlice
