import Link from 'next/link'
import { IProductList } from '@core/api/ProductLists/types'
import { LYSButton, LYSTable, LYSTypography } from '@core/components/Primitives'
import routes from '@core/config/routes'
import { useTranslation } from '@core/i18n/i18n'
import { useServices } from '@core/utils/ioc'
import { ShoppingCartOutlined } from '@ant-design/icons'
import style from './index.module.less'

interface OwnProps {
  productLists: IProductList[]
  handleAddListToCart: (slug: string) => Promise<void>
  isSaving: boolean
}

const ProductListWidgetTable: React.FC<OwnProps> = ({
  productLists,
  handleAddListToCart,
  isSaving,
}) => {
  const { router } = useServices()
  const { t } = useTranslation()

  const renderProductLists = () => (
    <LYSTable
      dataSource={productLists}
      pagination={false}
      showHeader={false}
      size="middle"
      rowKey={(record) => record.id}
    >
      <LYSTable.Column
        dataIndex="name"
        key="name"
        onCell={(record) => {
          return {
            onClick: () => {
              const route = routes.productList(record.id)
              router.pushAndScrollTop(route.href, route.as)
            },
          }
        }}
        render={(_columnValue: any, list: IProductList) => (
          <LYSTypography.Text>
            {list.label} ({list.itemsCount})
          </LYSTypography.Text>
        )}
      />
      <LYSTable.Column
        align="right"
        width={'fit-content'}
        onCell={(record) => {
          return {
            onClick: () => handleAddListToCart(record.id),
          }
        }}
        render={(_columnValue: any, _list: IProductList) => (
          <LYSButton
            size={'small'}
            type={'primary'}
            style={{
              borderRadius: '50%',
            }}
            disabled={isSaving}
            icon={<ShoppingCartOutlined rev={undefined} />}
          />
        )}
      />
    </LYSTable>
  )

  const renderNoLists = () => (
    <div className={style.emptyContainer}>{t('wishlist.noOrderText')}</div>
  )

  return (
    <div className={style.container}>
      <LYSTypography.Title level={3}>
        {t('wishlist.orderListText')}
      </LYSTypography.Title>
      <div className={style.content}>
        {productLists.length ? renderProductLists() : renderNoLists()}
      </div>

      <Link {...routes.productList()}>
        <LYSButton type="link" className={style.button}>
          {t('wishlist.showAllList')}
        </LYSButton>
      </Link>
    </div>
  )
}

export default ProductListWidgetTable
