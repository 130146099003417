import { LYSAlert } from '@core/components/Primitives'

interface Props {
  warning: string
}

const PrsimicLayoutWarning: React.FC<Props> = ({ warning }) => {
  return <LYSAlert message="Prismic Error" description={warning} type="error" />
}

export default PrsimicLayoutWarning
