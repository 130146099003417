import useChannel from '@core/hooks/channel/useChannel'
import { getContactEmail } from '@core/utils/contactInformation'
import useCorePrismicPlaceholders from '@original/prismic/usePrismicPlaceholders'

interface PlaceholderType {
  [key: string]: string
}

const useIgefaPrismicPlaceholders = () => {
  const { userChannel } = useChannel()
  const corePlaceholders = useCorePrismicPlaceholders()

  return {
    placeholders: {
      ...corePlaceholders.placeholders,
      '%EMAIL%': getContactEmail(userChannel) || '',
      '%PHONE%': userChannel?.clientFields?.phonenumber || '',
      '%BRANCH_OFFICE%': userChannel?.name || '',
    } as PlaceholderType,
  }
}

export default useIgefaPrismicPlaceholders
