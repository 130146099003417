/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect } from 'react'
import { ProductPromotionType } from '@core/api/Products/types'
import { LinkOutlined } from '@ant-design/icons'
import {
  LYSButton,
  LYSContainer,
  LYSTypography,
} from '@core/components/Primitives'
import ProductCarousel from '@core/components/ProductCollection/ProductCarousel'
import PromotionContext from '@core/components/shared/PromotionContext'
import { productCollectionViewEvent } from '@core/events/product'
import { IProductSliderSlice } from '@core/prismic/types'
import useGetProductCollectionFromSkuArray from '@core/prismic/useGetProductCollectionFromSkuArray'
import { useServices } from '@core/utils/ioc'
import { usePageContext } from '@core/utils/pages/PageContext'
import { useReturnPrismicContent } from '@core/prismic/hooks'
import style from './index.module.less'
import { PrismicSettingsProvider } from '../PrismicSettingsContext'
import { resolvePrismicLink } from '@core/prismic/util'
interface Props {
  slice: IProductSliderSlice
}

const ProductSliderSlice: React.FC<Props> = ({ slice }) => {
  const { viewport, headline, minimized, button_link, button_text } =
    slice.primary
  const prismicContextValue = React.useMemo(() => ({ minimized }), [minimized])
  const text = headline && headline[0]?.text
  const buttonText = button_text && button_text[0]?.text
  const { eventBus } = useServices()
  const pageContext = usePageContext()
  const { url, target } = resolvePrismicLink(button_link)
  const isValidUrl = !!url

  const { productItemsSorted, isLoading } = useGetProductCollectionFromSkuArray(
    slice.items
  )

  useEffect(() => {
    if (productItemsSorted.length) {
      eventBus.publish(
        productCollectionViewEvent(
          productItemsSorted,
          {
            isPromotion: true,
            promotionType: ProductPromotionType.Recommendation,
          },
          pageContext
        )
      )
    }
  }, [eventBus, productItemsSorted, pageContext])

  if (!productItemsSorted) return null

  const content = (
    <PromotionContext.Provider
      value={{
        isPromotion: true,
        promotionType: ProductPromotionType.Recommendation,
      }}
    >
      <PrismicSettingsProvider value={prismicContextValue}>
        <LYSContainer>
          {text !== '' && (
            <LYSTypography.Title className={style.headline} level={3}>
              {text}
              {buttonText !== '' && url && (
                <a href={url} target={target}>
                  <LYSButton
                    icon={<LinkOutlined rev={undefined} />}
                    size="large"
                    disabled={!isValidUrl}
                    type="link"
                  >
                    {buttonText}
                  </LYSButton>
                </a>
              )}
            </LYSTypography.Title>
          )}
          <ProductCarousel
            loading={isLoading}
            productItems={productItemsSorted}
          />
        </LYSContainer>
      </PrismicSettingsProvider>
    </PromotionContext.Provider>
  )

  return useReturnPrismicContent(content, viewport)
}

export default ProductSliderSlice
