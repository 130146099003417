import { LYSButton, LYSTable, LYSTypography } from '@core/components/Primitives'
import { useTranslation } from '@core/i18n/i18n'
import { ShoppingCartOutlined } from '@ant-design/icons'
import style from './index.module.less'
import { IProductSearch } from '@core/api/Products/types'
import ProductDetailLink from '@core/components/ProductCollection/ProductDetailLink'
import useCart from '@core/hooks/cart/useCart'

interface OwnProps {
  products: IProductSearch[] | undefined
  isLoading: boolean
}

const CustomTableCell: React.FC = ({ children, ...rest }) => (
  <td {...rest} className={style.tableCol}>
    {children}
  </td>
)

const MostBoughtTable: React.FC<OwnProps> = ({ products, isLoading }) => {
  const { t } = useTranslation()
  const { addToCart } = useCart()
  return (
    <div className={style.content}>
      <LYSTable
        dataSource={products}
        pagination={false}
        size="middle"
        rowKey={(record) => record.id}
        loading={isLoading}
        locale={{ emptyText: t('orders.noOrderExistentText') }}
        showHeader={false}
        components={{
          body: {
            cell: CustomTableCell,
          },
        }}
      >
        <LYSTable.Column
          key={'image'}
          render={(_columnValue: any, product: IProductSearch) => {
            return (
              <ProductDetailLink
                id={product.mainVariant.id}
                slug={product.mainVariant.slug}
                product={product}
              >
                <img
                  className={style.productImage}
                  src={product.mainVariant?.images?.[0]?.url}
                  alt={product.mainVariant.name}
                  title={product.mainVariant.name}
                  loading="lazy"
                />
              </ProductDetailLink>
            )
          }}
        />
        <LYSTable.Column
          title={t('orders.dateText')}
          key={'date'}
          render={(_columnValue: any, product: IProductSearch) => (
            <ProductDetailLink
              id={product.mainVariant.id}
              slug={product.mainVariant.slug}
              product={product}
            >
              <LYSTypography.Text strong={true}>
                {product.name}
              </LYSTypography.Text>
              <br />

              {product.shortDescription && (
                <>
                  <LYSTypography.Text>
                    {product.shortDescription}
                  </LYSTypography.Text>
                  <br />
                </>
              )}
              <LYSTypography.Text>
                {product.mainVariant.packagingUnits[0].name}
              </LYSTypography.Text>
            </ProductDetailLink>
          )}
        />

        <LYSTable.Column
          align="right"
          width={'fit-content'}
          onCell={(record) => {
            return {
              onClick: () =>
                addToCart(
                  record.mainVariant,
                  record?.name,
                  record.mainVariant.packagingUnits[0].multiplier
                ),
            }
          }}
          render={(_columnValue: any, _product: IProductSearch) => (
            <LYSButton
              size={'small'}
              type={'primary'}
              style={{
                borderRadius: '50%',
              }}
              icon={<ShoppingCartOutlined rev={undefined} />}
            />
          )}
        />
      </LYSTable>
    </div>
  )
}

export default MostBoughtTable
