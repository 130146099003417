import cn from 'classnames'
import { LYSButton, LYSContainer } from '@core/components/Primitives'
import { IButtonSlice } from '@core/prismic/types'
import { resolvePrismicLink } from '@core/prismic/util'
import style from './index.module.less'
import { useReturnPrismicContent } from '@core/prismic/hooks'

interface Props {
  slice: IButtonSlice
}

const ButtonSlice: React.FC<Props> = ({ slice }) => {
  const viewport = slice.primary.viewport
  const { url, target } = resolvePrismicLink(slice.primary.url)
  const isValidUrl = !!url
  const sanitizedSize: 'small' | 'large' | undefined =
    slice.primary.size === 'default' ? undefined : slice.primary.size

  const content = (
    <LYSContainer padded={{ x: true }}>
      <div className={cn(style.buttonWrap, style[slice.primary.alignment])}>
        <a href={url} target={target}>
          <LYSButton
            type={slice.primary.type}
            size={sanitizedSize}
            disabled={!isValidUrl}
          >
            {slice.primary.label}
          </LYSButton>
        </a>
      </div>
    </LYSContainer>
  )

  return useReturnPrismicContent(content, viewport)
}

export default ButtonSlice
