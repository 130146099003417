import useCurrentUser from '@core/hooks/user/useCurrentUser'

interface PlaceholderType {
  [key: string]: string
}

const usePrismicPlaceholders = () => {
  const { channel } = useCurrentUser()

  const placeholders: PlaceholderType = {
    '%EMAIL%': 'yschleich@lyska.io',
    '%CHANNELNAME%': channel?.name || '',
  }
  return {
    placeholders,
  }
}

export default usePrismicPlaceholders
