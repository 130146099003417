/* eslint-disable react-hooks/rules-of-hooks */
import { LYSContainer } from '@core/components/Primitives'
import PrismicImage from '@core/components/Prismic/PrismicImage'
import { useReturnPrismicContent } from '@core/prismic/hooks'
import { IImageTextSlice } from '@core/prismic/types'
import usePrismicPlaceholders from '@core/prismic/usePrismicPlaceholders'
import { convertRichTextToHtml } from '@core/prismic/util'
import isEmpty from 'lodash/isEmpty'

import style from './index.module.less'

interface Props {
  slice: IImageTextSlice
}

const ImageTextSlice: React.FC<Props> = ({ slice }) => {
  const { image, viewport } = slice.primary
  const position = slice.primary.image_position
  if (isEmpty(image)) return null

  const htmlString = convertRichTextToHtml(slice.primary.text)

  const { placeholders } = usePrismicPlaceholders()

  if (!htmlString) return null

  const textWithPlaceholders = htmlString.replace(
    /%\w+%/g,
    (placeholder) => placeholders[placeholder] || placeholder
  )

  const imageWidthStyle = slice.primary.image_width
    ? { maxWidth: `${slice.primary.image_width}% !important` }
    : undefined

  const html = { __html: textWithPlaceholders }

  const imageHtml = (
    <PrismicImage
      image={image}
      className={style[position]}
      style={imageWidthStyle}
    />
  )

  const content = (
    <LYSContainer padded={{ x: true }}>
      <div className={style.wrapper}>
        {position === 'left' && imageHtml}

        <div dangerouslySetInnerHTML={html} className={style.text} />

        {position === 'right' && imageHtml}
      </div>
    </LYSContainer>
  )

  return useReturnPrismicContent(content, viewport)
}

export default ImageTextSlice
