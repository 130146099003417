/* CONTENT DATA TYPES */
import { RecommendationTypes } from '@core/api/Recommendations/types'
import { EmarsysRecommendationLogic } from '@core/modules/emarsys-tracking/types'

export enum Viewport {
  DESKTOP_AND_MOBILE = 'desktop_and_mobile',
  DESKTOP = 'desktop',
  MOBILE = 'mobile',
}

export interface IBaseContentData {
  slug: string
  title: string
  image: PrismicImageElement
  description: PrismicTextElement[]
  seo_title: string
  seo_description: string
  custom_layout: PrismicDocumentLink
}

interface IBrandContentData extends IBaseContentData {
  banner: PrismicImageElement
}

interface IAwardContentData extends IBaseContentData {
  banner: PrismicImageElement
  description_long: PrismicTextElement[]
}

interface ICategoryContentData extends IBaseContentData {
  description_long: PrismicTextElement[]
}

interface IBrandContentData extends IBaseContentData {
  banner: PrismicImageElement
  description_long: PrismicTextElement[]
}
interface IInfoBannerContentData extends IBaseContentData {
  info_text: PrismicTextElement[]
  background_color: string
  text_color: string
  button_text: PrismicTextElement[]
  button_link: PrismicLinkElement
  button_background_color: string
  button_text_color: string
}

export type IProductContent = PrismicDocument<IBaseContentData>
export type ICategoryContent = PrismicDocument<ICategoryContentData>
export type IBrandContent = PrismicDocument<IBrandContentData>
export type IAwardContent = PrismicDocument<IAwardContentData>
export type IInfoBannerContent = PrismicDocument<IInfoBannerContentData>

/* DYNAMIC PAGE LAYOUT */
export interface IPageLayout {
  page_url: string
  page_title: string
  page_description: string
  indexable: boolean
  body: IPageLayoutSlice[]
}

export interface IPageSitemapConfig {
  slug: string
  indexable: boolean
}

type IPageLayoutSlice =
  | ISearchSlice
  | ITitleSlice
  | IRichTextSlice
  | ITeaserCollectionSlice
  | IDividerSlice
  | IProductCollectionSlice
  | IProductSliderSlice
  | IHTMLSlice
  | IQASlice
  | IButtonSlice
  | IEmarsysRecommendationsSlice
  | IImageTextSlice
  | IFilteredProductCollection
  | ITeaserSlider
  | ISystemRecommendationsSlice
  | IImageLinkGridSlice
  | IOrdersSlice
  | IProductListSlice
  | IMostBoughtSlice

export interface ITitleSlice
  extends PrismicSlice<
    {
      title: [PrismicTitleElement]
      identifier: string
      viewport: 'desktop_and_mobile' | 'desktop' | 'mobile'
    },
    any
  > {
  slice_type: 'title'
}

export interface IRichTextElement {
  text: PrismicTextElement[]
  width: number
  alignment: 'left' | 'center' | 'right'
  identifier: string
}

export interface IRichTextSlice extends PrismicSlice<IRichTextElement, any> {
  slice_type: 'rich_text'
  viewport?: 'desktop_and_mobile' | 'desktop' | 'mobile'
}
export interface IHTMLSlice
  extends PrismicSlice<
    {
      code: PrismicTextElement[]
      identifier: string
      viewport?: 'desktop_and_mobile' | 'desktop' | 'mobile'
    },
    any
  > {
  slice_type: 'html'
}

export interface ISearchSlice
  extends PrismicSlice<
    {
      code: PrismicTextElement[]
      identifier: string
      background_color: string
      viewport?: 'desktop_and_mobile' | 'desktop' | 'mobile'
    },
    any
  > {
  slice_type: 'search'
}

export interface IOrdersSlice
  extends PrismicSlice<
    {
      code: PrismicTextElement[]
      identifier: string
      viewport?: 'desktop_and_mobile' | 'desktop' | 'mobile'
    },
    any
  > {
  slice_type: 'orders'
}
export interface IMostBoughtSlice
  extends PrismicSlice<
    {
      code: PrismicTextElement[]
      identifier: string
      viewport?: 'desktop_and_mobile' | 'desktop' | 'mobile'
    },
    any
  > {
  slice_type: 'most_bought'
}

export interface IProductListSlice
  extends PrismicSlice<
    {
      code: PrismicTextElement[]
      identifier: string
      viewport?: 'desktop_and_mobile' | 'desktop' | 'mobile'
    },
    any
  > {
  slice_type: 'product_list'
}

export interface ITeaserElement {
  teaser_image: PrismicImageElement
  teaser_link: PrismicLinkElement
  teaser_image_mobile?: PrismicImageElement
}

export interface IImageTextElement {
  identifier: string
  image: PrismicImageElement
  image_position: 'left' | 'right'
  image_width: string
  text: PrismicTextElement[]
  viewport?: 'desktop_and_mobile' | 'desktop' | 'mobile'
}

export interface IImageLinkGridStyle {
  identifier: string
  headline?: PrismicTitleElement[]
  border?: boolean
  carousel?: boolean
  viewport?: 'desktop_and_mobile' | 'desktop' | 'mobile'
}

export interface IImageLinkGridElement {
  image: PrismicImageElement
  link: PrismicLinkElement
}

export interface IImageTextSlice extends PrismicSlice<IImageTextElement, any> {
  slice_type: 'image_and_text'
}

export interface IImageLinkGridSlice
  extends PrismicSlice<IImageLinkGridStyle, IImageLinkGridElement> {
  slice_type: 'image_link_grid'
}

export interface ITeaserStyle {
  teaser_style: '1' | '2' | '3' | '4'
  identifier: string
  image_border_style: 'bordered' | 'borderless'
  viewport?: 'desktop_and_mobile' | 'desktop' | 'mobile'
}

export interface ITeaserCollectionSlice
  extends PrismicSlice<ITeaserStyle, ITeaserElement> {
  slice_type: 'teaser_collection'
}

export interface IDividerSlice
  extends PrismicSlice<
    {
      identifier: string
      viewport?: 'desktop_and_mobile' | 'desktop' | 'mobile'
      margin?: 'large' | 'medium' | 'small'
      show_line?: boolean
    },
    any
  > {
  slice_type: 'divider'
}

export interface IQAElement {
  question: PrismicTextElement[]
  answer: PrismicTextElement[]
}

export interface IQASlice
  extends PrismicSlice<
    {
      identifier: string
      viewport?: 'desktop_and_mobile' | 'desktop' | 'mobile'
    },
    IQAElement
  > {
  slice_type: 'qa'
}

export interface IProductCollectionElement {
  product_codes: PrismicTextElement[]
  identifier: string
  show_category_navigation: boolean
  viewport?: 'desktop_and_mobile' | 'desktop' | 'mobile'
  minimized?: boolean
}

export interface IProductCollectionSlice
  extends PrismicSlice<IProductCollectionElement, any> {
  slice_type: 'product_collection'
}

export interface IProductSliderElement {
  product_code: string
}

export interface IEmarsysFallbackItem {
  fallback_product: string
}

export interface ISystemFallbackItem {
  fallback_product: string
}

export interface IProductSliderSlice
  extends PrismicSlice<
    {
      identifier: string
      viewport?: 'desktop_and_mobile' | 'desktop' | 'mobile'
      headline?: [PrismicTitleElement]
      button_text?: [PrismicTitleElement]
      button_link?: PrismicLinkElement
      icon?: boolean
      minimized?: boolean
    },
    IProductSliderElement
  > {
  slice_type: 'product_slider'
}
export interface IButtonElement {
  label: string
  url: PrismicLinkElement
  type: 'link' | 'default' | 'primary' | 'dashed' | 'text'
  size: 'small' | 'default' | 'large'
  alignment: 'center' | 'left' | 'right'
  identifier: string
  viewport?: 'desktop_and_mobile' | 'desktop' | 'mobile'
}

export interface IButtonSlice extends PrismicSlice<IButtonElement, any> {
  slice_type: 'button'
}

export interface IEmarsysRecommendationsSlice
  extends PrismicSlice<
    {
      logic: EmarsysRecommendationLogic
      limit?: number
      identifier: string
      viewport?: 'desktop_and_mobile' | 'desktop' | 'mobile'
      minimized?: boolean
    },
    IEmarsysFallbackItem
  > {
  slice_type: 'emarsys_recommendations'
}

export enum AllRecommendationTypes {
  ALL = 'all',
}
export interface ISystemRecommendationsSlice
  extends PrismicSlice<
    {
      logic: RecommendationTypes | AllRecommendationTypes
      limit?: number
      identifier: string
      viewport?: 'desktop_and_mobile' | 'desktop' | 'mobile'
      minimized?: boolean
    },
    ISystemFallbackItem
  > {
  slice_type: 'system_recommendations'
}

export interface IFilteredProductCollection
  extends PrismicSlice<
    {
      filter_query: string
      taxonomy_id: string
      product_count_limit: number
      identifier: string
      use_pagination: boolean
      viewport?: 'desktop_and_mobile' | 'desktop' | 'mobile'
      minimized?: boolean
    },
    any
  > {
  slice_type: 'filtered_product_collection'
}

export interface ITeaserSlide {
  c2a?: string
  link?: PrismicLinkElement
  headline: PrismicTitleElement[]
  copy: PrismicTextElement[]
  image: PrismicImageElement
  mobile_image?: PrismicImageElement
  text_color?: string
  viewport?: 'desktop_and_mobile' | 'desktop' | 'mobile'
}

export interface ITeaserSlider
  extends PrismicSlice<
    {
      identifier: string
      viewport?: 'desktop_and_mobile' | 'desktop' | 'mobile'
      image_border_style: 'bordered' | 'borderless'
    },
    ITeaserSlide
  > {
  slice_type: 'teaser_slider'
}

export type IPageLayoutDocument = PrismicDocument<IPageLayout>
