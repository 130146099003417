import { LYSContainer } from '@core/components/Primitives'
import { useReturnPrismicContent } from '@core/prismic/hooks'
import { IHTMLSlice } from '@core/prismic/types'
import style from './index.module.less'
interface Props {
  slice: IHTMLSlice
}

const HTMLSlice: React.FC<Props> = ({ slice }) => {
  const viewport = slice.primary.viewport
  const html = { __html: slice.primary.code[0].text }

  const content = (
    <LYSContainer padded={{ x: true }}>
      <div className={style.htmlContainer} dangerouslySetInnerHTML={html} />
    </LYSContainer>
  )

  return useReturnPrismicContent(content, viewport)
}

export default HTMLSlice
