import { LYSDivider, LYSContainer } from '@core/components/Primitives'
import { useReturnPrismicContent } from '@core/prismic/hooks'
import { IDividerSlice } from '@core/prismic/types'
import style from './index.module.less'

interface Props {
  slice: IDividerSlice
}

const DividerSlice: React.FC<Props> = ({ slice }) => {
  const { viewport, margin, show_line } = slice.primary

  const content = (
    <LYSContainer padded={{ x: true }}>
      <div
        className={
          margin === 'large'
            ? style.largeMargin
            : margin === 'medium'
            ? style.mediumMargin
            : margin === 'small'
            ? style.smallMargin
            : undefined
        }
      >
        {show_line && <LYSDivider />}
      </div>
    </LYSContainer>
  )

  return useReturnPrismicContent(content, viewport)
}

export default DividerSlice
