/* eslint-disable react-hooks/rules-of-hooks */
import * as React from 'react'
import {
  IProductsQuery,
  IProductCollection,
  ProductPromotionType,
} from '@core/api/Products/types'
import { LYSContainer } from '@core/components/Primitives'
import ProductCollection from '@original/components/ProductCollection'
import useFetchProducts from '@core/utils/useFetchProducts'
import { IProductCollectionSlice } from '../../../types'
import { useReturnPrismicContent } from '@core/prismic/hooks'
import { PrismicSettingsProvider } from '../PrismicSettingsContext'

interface Props {
  slice: IProductCollectionSlice
}

const ProductCollectionSlice: React.FC<Props> = ({ slice }) => {
  const { viewport, minimized } = slice.primary
  const prismicContextValue = React.useMemo(() => ({ minimized }), [minimized])

  // we need to check for whitespace or line breaks in user input
  const productCodes: string[] = slice.primary.product_codes
    .map((item) => item.text.trim())
    .filter((code) => code)

  const showCategoryFilter = slice.primary.show_category_navigation

  const productsQueryFilter: IProductsQuery = {
    filter: { sku: productCodes },
  }

  const [productFetchData] = useFetchProducts(
    productsQueryFilter,
    !productCodes.length
  )

  const productCollection = productFetchData.payload

  if (!productCollection) return null

  const productItemsSorted = productCollection?.hits.sort(
    (a, b) => productCodes.indexOf(a.sku) - productCodes.indexOf(b.sku)
  )
  const productCollectionWithSortedItems: IProductCollection | undefined = {
    ...productCollection!,
    hits: productItemsSorted,
  }

  const content = (
    <PrismicSettingsProvider value={prismicContextValue}>
      <LYSContainer padded={{ x: true }}>
        <ProductCollection
          initialData={productCollectionWithSortedItems}
          hideControls={true}
          hidePagination={true}
          isPromotion={true}
          promotionType={ProductPromotionType.Recommendation}
          showCategoryFilter={showCategoryFilter}
        />
      </LYSContainer>
    </PrismicSettingsProvider>
  )

  return useReturnPrismicContent(content, viewport)
}

export default ProductCollectionSlice
