import ProductListWidgetTable from './ProductListWidgetTable'
import useProductLists from '@core/hooks/productList/useProductLists'
import { IProductListSlice } from '@core/prismic/types'
import { useState } from 'react'
import { useReturnPrismicContent } from '@core/prismic/hooks'

interface Props {
  slice: IProductListSlice
}

const ProductListSlice: React.FC<Props> = ({ slice }) => {
  const [isSaving, setSaving] = useState<boolean>(false)
  const { productLists, addProductListToCart } = useProductLists()
  const viewport = slice.primary.viewport
  const handleAddListToCart = async (productListId: string) => {
    setSaving(true)
    await addProductListToCart(productListId)
    setSaving(false)
  }

  const content = (
    <ProductListWidgetTable
      handleAddListToCart={handleAddListToCart}
      productLists={productLists.slice(0, 3)}
      isSaving={isSaving}
    />
  )

  return useReturnPrismicContent(content, viewport)
}

export default ProductListSlice
