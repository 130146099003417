import { useMemo } from 'react'
import {
  IExternalOrdersQuery,
  IExternalOrderCollection,
} from '@core/api/ExternalOrders/types'
import { useServices } from '@core/utils/ioc'
import useGenericFetchFromApi, { IStateType } from './useGenericFetchFromApi'

const useFetchExternalOrders = (
  initialFilter?: IExternalOrdersQuery
): [
  IStateType<IExternalOrderCollection>,
  (filter: IExternalOrdersQuery) => void,
  () => void
] => {
  const { api } = useServices()
  const fetchFunction = useMemo(
    () => api.externalOrders.fetchExternalOrders.bind(api, initialFilter),
    [api, initialFilter]
  )

  return useGenericFetchFromApi<
    IExternalOrdersQuery | undefined,
    IExternalOrderCollection
  >({
    initialQueryParams: initialFilter,
    fetchFunction,
  })
}

export default useFetchExternalOrders
