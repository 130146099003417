import { LYSContainer, LYSTypography } from '@core/components/Primitives'
import PrismicImage from '@core/components/Prismic/PrismicImage'
import { useReturnPrismicContent, useViewport } from '@core/prismic/hooks'
import { IImageLinkGridElement, IImageLinkGridSlice } from '@core/prismic/types'
import { resolvePrismicLink } from '@core/prismic/util'
import style from './index.module.less'
import cn from 'classnames'
import Carousel from 'react-multi-carousel'
import 'react-multi-carousel/lib/styles.css'

interface Props {
  slice: IImageLinkGridSlice
}

const ImageLinkGridElement: React.FC<{ element: IImageLinkGridElement }> = ({
  element,
}) => {
  const linkTarget = element.link && resolvePrismicLink(element.link)
  const image = (
    <div className={style.singleImageContainer}>
      <PrismicImage
        image={element.image}
        className={cn(style.image, !linkTarget.url && style.imageWithoutLink)}
      />
    </div>
  )
  return linkTarget ? (
    <a href={linkTarget.url} target={linkTarget.target}>
      {image}
    </a>
  ) : (
    image
  )
}

const ImageLinkGridSlice: React.FC<Props> = ({ slice }) => {
  const { viewport, headline, border, carousel } = slice.primary
  const { isTouchDevice } = useViewport()
  const text = headline && headline[0]?.text

  const prismicHeadline = text !== '' && (
    <LYSTypography.Title className={style.headline} level={2}>
      {text}
    </LYSTypography.Title>
  )

  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 8,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 5,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 4,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 2,
    },
  }

  const contentAsCarousel = (
    <>
      {prismicHeadline}
      {slice.items.length >= 1 && (
        <Carousel
          className={cn(style.carousel, border && style.border)}
          autoPlay={!isTouchDevice}
          autoPlaySpeed={3000}
          centerMode={!isTouchDevice}
          swipeable
          arrows={!isTouchDevice}
          showDots={isTouchDevice}
          infinite
          responsive={responsive}
        >
          {slice.items.map((item, i) => (
            <ImageLinkGridElement key={i} element={item} />
          ))}
        </Carousel>
      )}
    </>
  )

  const content = (
    <LYSContainer>
      {carousel ? (
        contentAsCarousel
      ) : (
        <>
          {prismicHeadline}
          {slice.items.length >= 1 && (
            <div className={cn(style.imageContainer, border && style.border)}>
              {slice.items.map((item, i) => (
                <ImageLinkGridElement key={i} element={item} />
              ))}
            </div>
          )}
        </>
      )}
    </LYSContainer>
  )

  return useReturnPrismicContent(content, viewport)
}

export default ImageLinkGridSlice
