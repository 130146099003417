import Link from 'next/link'
import { IOrderWidgetHistoryItem } from '@core/components/Me/Dashboard/widgets/OrdersWidget/useOrderWidgetHistory'
import Price from '@core/components/Price'
import { LYSTable, LYSTypography } from '@core/components/Primitives'
import routes from '@core/config/routes'
import useChannel from '@core/hooks/channel/useChannel'
import { useTranslation } from '@core/i18n/i18n'
import { formatDate } from '@core/utils/formatDateTime'
import style from './index.module.less'

interface OwnProps {
  history: IOrderWidgetHistoryItem[]
  isLoading: boolean
}

const CustomTableCell: React.FC = ({ children, ...rest }) => (
  <td {...rest} className={style.tableCol}>
    {children}
  </td>
)

const OrderLink: React.FC<{ order: IOrderWidgetHistoryItem }> = ({
  order,
  children,
}) => (
  <Link {...routes.orderOverview(order.id)}>
    <a className={style.tableColLink}>{children}</a>
  </Link>
)

const OrderWidgetTable: React.FC<OwnProps> = ({ history, isLoading }) => {
  const { t } = useTranslation()
  const { userChannel } = useChannel()
  return (
    <div className={style.content}>
      <LYSTable
        dataSource={history}
        pagination={false}
        size="middle"
        rowKey={(record) => record.id}
        loading={isLoading}
        locale={{ emptyText: t('orders.noOrderExistentText') }}
        showHeader={false}
        components={{
          body: {
            cell: CustomTableCell,
          },
        }}
      >
        <LYSTable.Column
          title={t('orders.dateText')}
          key={'date'}
          render={(_columnValue: any, order: IOrderWidgetHistoryItem) => (
            <OrderLink order={order}>
              <LYSTypography.Text>{formatDate(order.date)}</LYSTypography.Text>
            </OrderLink>
          )}
        />
        <LYSTable.Column
          key={'orderItems'}
          render={(_columnValue: any, order: IOrderWidgetHistoryItem) => (
            <OrderLink order={order}>
              <LYSTypography.Text>
                {t('orders.table.items', { amount: order.items })}
              </LYSTypography.Text>
            </OrderLink>
          )}
        />

        <LYSTable.Column
          key={'price'}
          align="right"
          render={(_columnValue: any, order: IOrderWidgetHistoryItem) =>
            userChannel && (
              <OrderLink order={order}>
                <Price
                  value={order.priceTotal[userChannel.displayedPriceType]}
                  className={style.price}
                />
              </OrderLink>
            )
          }
        />
      </LYSTable>
    </div>
  )
}

export default OrderWidgetTable
