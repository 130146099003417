import React, {
  KeyboardEvent,
  useEffect,
  useMemo,
  useState,
  useCallback,
} from 'react'
import { SelectValue } from 'antd/lib/select'
import cn from 'classnames'
import {
  CloseOutlined,
  LoadingOutlined,
  SearchOutlined,
} from '@ant-design/icons'
import {
  buildAutoSuggestOptions,
  findMaxWeightedTaxonomyNodes,
} from '@core/components/AutoSuggest/AutoSuggestOptions'
import useSuggestions, {
  IAutoCompleteSuggestions,
} from '@core/components/AutoSuggest/useSuggestions'
import {
  LYSAutoComplete,
  LYSButton,
  LYSInput,
} from '@core/components/Primitives'
import config from '@core/config/config'
import routes from '@core/config/routes'
import { useTranslation } from '@core/i18n/i18n'
import { useServices } from '@core/utils/ioc'
import SearchTypeSwitch, { AvailableSearchTypes } from './SearchTypeSwitch'
import style from './index.module.less'
import useRecommendations from '@core/hooks/useRecommendations'
import { RecommendationTypes } from '@core/api/Recommendations/types'
import useLastSearches from '@core/hooks/useLastSearches'

interface Props {
  autoFocus?: boolean
  staticCloseButton?: boolean
  className?: string
  onClose?: () => void
  defaultSearchType?: AvailableSearchTypes
  showTaxonomies?: boolean
}

export const searchTextIsValid = (searchText: string) =>
  searchText.replace(/\s/g, '').length >= 3

const AutoSuggest: React.FC<Props> = ({
  autoFocus,
  staticCloseButton,
  onClose,
  className,
  defaultSearchType,
  showTaxonomies = false,
}) => {
  const { t } = useTranslation()
  const { router, productQueryService } = useServices()
  const [searchText, setSearchText] = useState<string>(
    router.query?.search?.toString() ?? ''
  )
  const [searchType, setSearchType] = useState(
    defaultSearchType || AvailableSearchTypes.PRODUCT
  )
  const [isOpen, setOpen] = useState<boolean>(false)

  const [enterPressed, setEnterPressed] = useState<boolean>(false)

  const { isLoading, suggestions, loadSuggestions, clearSuggestions } =
    useSuggestions(searchType, showTaxonomies)

  const { recommendations } = useRecommendations({
    recommendationType: RecommendationTypes.MOST_BOUGHT_VARIANT,
    recommendationsEnabled: config.features.quickSearchRecommendations.enabled,
  })

  const { lastSearchStrings } = useLastSearches(isOpen)
  const campaignUrl = suggestions.campaignUrl
  const weightedTaxonomies = useMemo(
    () =>
      suggestions.taxonomyAggregationRoot && showTaxonomies
        ? findMaxWeightedTaxonomyNodes(suggestions.taxonomyAggregationRoot!)
        : undefined,
    [suggestions.taxonomyAggregationRoot, showTaxonomies]
  )

  const isPartialMatch = (searchText: string) => {
    if (!suggestions) {
      return false
    }
    if (
      suggestions.productSuggestions &&
      suggestions.productSuggestions.length > 0
    ) {
      return suggestions.productSuggestions[0].sku.includes(searchText)
    }
    return false
  }

  const isExactProductSearch = useCallback(() => {
    return (
      productQueryService.exactProductSearchEnabled() &&
      (productQueryService.searchTextIsProductNumber(searchText) ||
        productQueryService.searchTextMightBeProductNumber(searchText))
    )
  }, [productQueryService, searchText])

  useEffect(() => {
    clearSuggestions()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchType])

  useEffect(() => {
    if (isExactProductSearch() && suggestions && !isLoading && enterPressed) {
      if (
        suggestions.productSuggestions &&
        suggestions.productSuggestions.length > 0
      ) {
        // if we have only on product, we redirect on enter
        if (suggestions.productSuggestions.length === 1) {
          redirectToProductPage(suggestions)
        }
        // if we have multiple products, but the first product sku partially matches
        // we also redirect to the product page
        else if (suggestions.productSuggestions[0].sku.includes(searchText)) {
          redirectToProductPage(suggestions)
        }
      } else if (suggestions?.productSuggestions?.length === 0) {
        const searchRoute = routes.searchResult(searchText, searchType)
        router.pushAndScrollTop(searchRoute.href, searchRoute.as)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [suggestions, isLoading, isExactProductSearch, router, enterPressed])

  const redirectToProductPage = (suggestions: IAutoCompleteSuggestions) => {
    if (suggestions.productSuggestions) {
      const product = suggestions.productSuggestions[0]
      const { mainVariant } = product
      const { slug, id } = mainVariant
      const searchRoute = routes.productDetail(slug, id)
      setOpen(false)
      setEnterPressed(false)
      router.pushAndScrollTop(searchRoute.href, searchRoute.as)
    }
  }

  const handleChange = (value: SelectValue) => {
    if (!(typeof value === 'string')) return
    setSearchText(value)
    setOpen(true)
    if (searchTextIsValid(value)) {
      loadSuggestions(value)
    }
  }
  const handleOpen = (value: boolean) => {
    setOpen(value)
  }
  const handleClose = () => {
    setSearchText('')
    setOpen(false)
    setEnterPressed(false)
    clearSuggestions()
    if (onClose) onClose()
  }
  const handleClear = () => {
    setSearchText('')
    // Ant Autocomplete actually closes by itself when pressing ESC so reopen
    setOpen(true)
    setEnterPressed(false)
    clearSuggestions()
  }
  const handleSelect = () => {
    setSearchText(searchText)
    setOpen(false)
    setEnterPressed(false)
  }

  const handleSearch = () => {
    if (searchTextIsValid(searchText)) {
      if (
        !isExactProductSearch() ||
        suggestions?.productSuggestions?.length === 0
      ) {
        const searchRoute = routes.searchResult(searchText, searchType)
        router.pushAndScrollTop(searchRoute.href, searchRoute.as)
      } else if (
        suggestions?.productSuggestions?.length === 1 ||
        isPartialMatch(searchText)
      ) {
        setEnterPressed(true)
      } else {
        setEnterPressed(true)
        loadSuggestions(searchText)
      }
    }
  }
  const handleKeydown = (e: KeyboardEvent) => {
    // some browsers
    if (e.key.toLowerCase() === 'enter') handleSearch()
    if (e.key.toLowerCase() === 'escape') handleClear()
  }

  const inputPrefix = (
    <LYSButton
      type={'link'}
      icon={
        isLoading ? (
          <LoadingOutlined rev={undefined} />
        ) : (
          <SearchOutlined rev={undefined} />
        )
      }
      className={style.prefix}
    />
  )

  const inputSuffix = (searchText || staticCloseButton) && (
    <LYSButton
      type={'link'}
      icon={<CloseOutlined rev={undefined} />}
      className={style.suffix}
      onClick={handleClose}
    />
  )

  const options = buildAutoSuggestOptions({
    campaignUrl,
    taxonomies: weightedTaxonomies,
    suggestions,
    recommendations,
    lastSearchStrings,
    searchText,
    isLoading,
    searchType,
    t,
  })

  const hasTypeSwitch = config.features.searchTypeSwitchEnabled

  const placeholder =
    searchType === AvailableSearchTypes.PRODUCT
      ? t('search.searchField.productSearchPlaceholder')
      : t('search.searchField.documentSearchPlaceholder')

  return (
    <div className={cn(style.autoSuggest_holder, className)}>
      <LYSAutoComplete
        className={cn(
          style.autoSuggest,
          hasTypeSwitch && style.autoSuggestWithTypeSwitch
        )}
        dropdownClassName={style.dropdown}
        getPopupContainer={(trigger) => trigger.parentNode! as HTMLElement}
        defaultActiveFirstOption={false}
        value={searchText}
        open={isOpen}
        autoFocus={autoFocus}
        options={options}
        onDropdownVisibleChange={handleOpen}
        onChange={handleChange}
        onSelect={handleSelect}
        dropdownMatchSelectWidth={false}
      >
        <LYSInput
          data-testid="auto-suggest-input"
          prefix={inputPrefix}
          suffix={inputSuffix}
          placeholder={placeholder}
          onKeyDown={handleKeydown}
          onFocus={() => searchText === '' && clearSuggestions()}
        />
      </LYSAutoComplete>
      {hasTypeSwitch && (
        <SearchTypeSwitch active={searchType} onChange={setSearchType} />
      )}
    </div>
  )
}

export default AutoSuggest
