import cn from 'classnames'
import style from './index.module.less'

interface Props {
  id: string
}

const LayoutSection: React.FC<Props> = ({ id, children }) => {
  return (
    <div
      className={cn(style.slice, 'prismic-element')}
      id={id?.length ? id : undefined}
    >
      {children}
    </div>
  )
}

export default LayoutSection
