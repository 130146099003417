import {
  ICartPrice,
  IFees,
  IOrderBillingInformation,
  IOrderShippingAddress,
  IPickupLocation,
} from '@core/api/Cart/types'
import { IOrderClientFieldsCollection } from '@core/api/Checkout/types'
import { IGrossPrice } from '@core/api/Products/types'
import { IClientFields } from '../types'

export interface IOrderCollection {
  total: number
  orders: IOrder[]
}

export enum OrderStatus {
  pending = 'pending',
  placed = 'placed',
  canceled = 'canceled',
  failed = 'failed',
  open = 'open',
  leftWarehouse = 'left warehouse',
  inProgress = 'in progress',
  unknown = 'unknown',
}

export enum OrderItemStatus {
  PENDING = 'pending',
  PLACED = 'placed',
  SHIPPED = 'shipped',
  PARTIALSHIPPED = 'partialShipped',
  DELIVERED = 'delivered',
  CANCELED = 'canceled',
  FAILED = 'failed',
  RETURNED = 'returned',
  UNKNOWN = 'unknown',
}

export enum PaymentStatus {
  pending = 'pending',
  paid = 'paid',
  failed = 'failed',
  canceled = 'canceled',
  unknown = 'unknown',
  open = 'open',
  fullyPaid = 'fully paid',
  failedOrCanceled = 'fehlgeschlagen oder abgebrochen',
}

export interface IOrder {
  id: string
  date: string
  customerInformation: IOrderCustomer
  billingInformation: IOrderBillingInformation
  shippingAddress: IOrderShippingAddress
  shippingMethod: string
  paymentMethod: string
  paymentStatus: PaymentStatus
  paymentEvents?: any[]
  price: ICartPrice
  items: IOrderItem[]
  clientFields: IOrderClientFieldsCollection
  purchaseOrderNumber: string
  status: OrderStatus
  attachments: IOrderAttachment[]
  shipments: IShipment[]
  estimatedDeliveryDate?: string
  wishedDeliveryDate?: { from: string; to: string }
}

export interface IShipment {
  id: string
  status: string
  trackingLink: string | null
  pickupLocation?: IPickupLocation
}

export interface IOrderAttachment {
  id: string
  name: string
  filename: string
  filesize: string
  mime: string
  url: string
  tags: { [key: string]: any }
  clientFields: IClientFields
}

export interface IOrderCustomer {
  customerId: string
  name: string
  email: string
  userEmail: string
  userId: string
}

export interface IOrderItem {
  id: string
  product: IOrderItemProductInformation
  price: IGrossPrice
  status: OrderItemStatus
  quantity: number
  fees: IFees[]
  pricePerUnit: IGrossPrice
  totalPrice: IGrossPrice
  totalPricePerUnit: IGrossPrice
}

export interface IOrderItemProductInformation {
  id: string
  sku: string
  name: string
  imageUrl: string
  shortDescription: string
  variant: IOrderItemVariantInformation
  variationName?: string
  skuProvidedBySupplier?: string
}

export interface IOrderItemVariantInformation {
  id: string
  name: string
  imageUrl: string
  slug: string
  sku: string
  gtin?: string
  clientFields?: { [key: string]: any }
}

export interface IOrdersQuery {
  page?: number
  filter?: IOrdersQueryFilter
  offset?: number
  limit?: number
  order: IOrdersQueryOrder
}

export interface IOrdersQueryOrder {
  [key: string]: OrderingTypes
}

export enum OrderingTypes {
  asc = 'asc',
  desc = 'desc',
}
export interface IOrdersQueryFilter {
  [key: string]: string[] | string | IOrdersQueryFilterRange
}

export interface IOrdersQueryFilterRange {
  gt: number
  gte: number
  lt: number
  lte: number
}
