import React, { useCallback, useEffect, useState } from 'react'
import {
  AllRecommendationTypes,
  ISystemRecommendationsSlice,
} from '@core/prismic/types'
import { IProductSearch } from '@core/api/Products/types'
import { LYSContainer } from '@core/components/Primitives'
import ProductCarousel from '@core/components/ProductCollection/ProductCarousel'
import { useServices } from '@core/utils/ioc'
import useRecommendations from '@core/hooks/useRecommendations'
import { useReturnPrismicContent } from '@core/prismic/hooks'
import { PrismicSettingsProvider } from '../PrismicSettingsContext'

const DEFAULT_LIMIT = 10

interface Props {
  slice: ISystemRecommendationsSlice
}

const SystemRecommendationsSlice: React.FC<Props> = ({ slice }) => {
  const { logic, limit, viewport, minimized } = slice.primary
  const prismicContextValue = React.useMemo(() => ({ minimized }), [minimized])
  const fallbackItems = slice.items
  const { api } = useServices()
  const [products, setProducts] = useState<IProductSearch[]>([])
  const [isProductLoading, setIsProductLoading] = useState<boolean>(false)
  const recommendationLimit: number = limit || DEFAULT_LIMIT

  const { recommendations, isLoading } = useRecommendations({
    recommendationType:
      logic === AllRecommendationTypes.ALL ? undefined : logic,
  })

  const fetchFallbackProducts = useCallback(
    async (productSkus?: string[]) => {
      if (!productSkus || productSkus.length <= 0) {
        setIsProductLoading(false)
        return
      }

      setIsProductLoading(true)

      const fetchedProducts = await api.products.fetchProducts({
        filter: { sku: productSkus },
        page: 1,
        requiresAggregations: 0,
      })

      setProducts(fetchedProducts.hits)
      setIsProductLoading(false)
    },
    [setProducts, api.products, setIsProductLoading]
  )

  useEffect(() => {
    const fallbackProductSkus = fallbackItems?.map((el) => el.fallback_product)

    if (recommendations && recommendations?.length > 0) {
      if (recommendations.length > recommendationLimit) {
        recommendations.length = recommendationLimit
      }
      setProducts(recommendations)
    } else {
      fetchFallbackProducts(fallbackProductSkus)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recommendations, logic, fallbackItems, fetchFallbackProducts])

  const content =
    products.length > 0 ? (
      <PrismicSettingsProvider value={prismicContextValue}>
        <LYSContainer padded={{ x: true }}>
          <ProductCarousel
            productItems={products}
            loading={isProductLoading || isLoading}
          />
        </LYSContainer>
      </PrismicSettingsProvider>
    ) : null

  return useReturnPrismicContent(content, viewport)
}

export default SystemRecommendationsSlice
