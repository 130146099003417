import { useEffect } from 'react'
import useGenericFetchFromApi from '@core/utils/useGenericFetchFromApi'
import { usePageContext } from '@core/utils/pages/PageContext'
import { Viewport } from './types'

type IContentFetchFunction<TContentType> = (
  uid: string | undefined
) => Promise<TContentType>

// const createContentHook = <TContentType>(
//   fetchFunction: IContentFetchFunction<TContentType>
// ) => (uid: string | undefined, preloadData?: TContentType) =>
//   useContent(fetchFunction, uid, preloadData)

export const useContent = <TContentType>(
  fetchFunction: IContentFetchFunction<TContentType>,
  uid: string | undefined,
  preloadData?: TContentType
) => {
  const [data, setQuery] = useGenericFetchFromApi<
    string | undefined,
    TContentType | undefined
  >({
    initialQueryParams: uid,
    fetchFunction,
    disable: !!preloadData,
  })

  useEffect(() => {
    setQuery(uid)
  }, [uid, setQuery])

  return {
    ...data,
    payload: data.payload || preloadData,
  }
}

export const useViewport = () => {
  const {
    props: { isMobile, isTablet },
  } = usePageContext()
  const isTouchDevice = isMobile || isTablet
  const isDesktop = !isMobile && !isTablet

  return { isTouchDevice, isDesktop }
}

export const useReturnPrismicContent = (
  content: JSX.Element | null,
  viewport?: string
) => {
  const { isDesktop, isTouchDevice } = useViewport()

  switch (viewport) {
    case Viewport.DESKTOP_AND_MOBILE:
      return content
    case Viewport.DESKTOP:
      if (isDesktop) return content
      else return null
    case Viewport.MOBILE:
      if (isTouchDevice) return content
      else return null
    default:
      return content
  }
}
